import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import FormSimple from "~components/forms/FormSimple";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import SubTitle from "~components/typography/SubTitle";

const Contact: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			image: file(relativePath: { eq: "components/sections/Contact/contact.jpg" }) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: NORTH })
				}
			}
		}
	`);

	const image = getImage(data.image);

	return (
		<Container>
			<Grid cols={2}>
				<div className="relative h-full min-h-[480px]">
					{image ? (
						<GatsbyImage image={image} className="absolute inset-0 h-full w-full rounded object-cover" alt="" />
					) : null}
				</div>
				<div>
					<SubTitle className="mb-2">Kontakt</SubTitle>
					<H2 className="mb-4">So erreichst Du mich</H2>
					<P className="mb-3">Erreiche mich ganz einfach per E-Mail und ich melde mich bei Dir!</P>
					<FormSimple />
				</div>
			</Grid>
		</Container>
	);
};

export default Contact;
