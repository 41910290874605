import React from "react";
import Button from "~components/common/Button";
import Datenschutz from "~components/forms/elements/Datenschutz";
import Input from "~components/forms/elements/Input";
import Textarea from "~components/forms/elements/Textarea";

const FormSimple: React.FC = () => {
	return (
		<form name="Kontaktformular" method="POST" data-netlify="true" action="/anfrage-gesendet/" className="space-y-4">
			<input type="hidden" name="form-name" value="Kontaktformular" />
			<Input
				type="text"
				label="Firma"
				id="firma"
				name="Firma"
				placeholder="Dein Unternehmensname"
				autoComplete="organization"
			/>
			<div className="grid-cols-2 gap-4 sm:grid ">
				<Input type="text" label="Vorname" id="vorname" name="Vorname" placeholder="Dein Vorname" required />
				<Input type="text" label="Nachname" id="nachname" name="Nachname" placeholder="Dein Nachname" required />
				<Input
					type="text"
					label="Schutz for Robotern. Bitte fülle das nächste Feld nicht aus"
					id="bot-field"
					name="bot-field"
					placeholder="Firm"
					classNameContainer="hidden"
				/>
			</div>
			<Input
				type="tel"
				label="Telefon"
				id="telefon"
				name="Telefon"
				placeholder="Deine Telefonnummer"
				autoComplete="tel"
				inputMode="numeric"
				required
			/>
			<Input
				type="email"
				label="E-Mail"
				id="email"
				name="E-Mail"
				placeholder="Deine E-Mailadresse"
				autoComplete="email"
				required
			/>
			<Textarea label="Kommentar" id="kommentar" name="Kommentar" placeholder="Deine Nachricht" required />
			<Button submit text="Anfrage senden" className="w-full" />
			<Datenschutz />
		</form>
	);
};

export default FormSimple;
