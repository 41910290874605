import React from "react";
import Blockquote from "~components/typography/Blockquote";

// Components
import Container from "~components/common/Container";
import SubTitle from "~components/typography/SubTitle";

interface Props {
	subtitle: string;
	text: string;
	author?: string;
}

const PageHeaderBlockquote: React.FC<Props> = ({ subtitle, text, author }) => {
	return (
		<Container backgroundColor="bg-primary-100" noMargin className="py-8 text-center md:py-16">
			<SubTitle className="mb-2">{subtitle}</SubTitle>
			<div className="mx-auto max-w-4xl">
				<Blockquote author={author ? author : undefined} text={text} />
			</div>
		</Container>
	);
};

export default PageHeaderBlockquote;
